import { IProductPrices } from "@/types/api";

type ProductPricesProps = {
    initial: number;
    current: number;
    discount: number | false;
    isLoading: boolean;
};

const ProductPrices: React.FC<ProductPricesProps> = ({ current, initial, discount, isLoading }) => {
    return (
        <div className="flex flex-row space-x-2">
            <span className={`text-2xl font-semibold tracking-tight ${discount} ? 'text-accent' : '' }`}>{current}</span>
            {discount && (<div>-{discount}%</div>)}
        </div>
    );
};

export default ProductPrices;
