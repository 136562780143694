'use client';

import type { IProduct } from '@/types/api';
import { Card, CardHeader, CardTitle, CardDescription } from '../ui/card';
import Image from 'next/image';
import { Key, useCallback, useEffect, useState } from 'react';
import { AppConfig } from '@/utils/AppConfig';
import { Alert, AlertDescription, AlertTitle } from '../ui/alert';
import { Skeleton } from '../ui/skeleton';
import { useRouter } from 'next/navigation';
import Link from 'next/link';
import ProductGrid from '../custom/products/ProductGrid';

export function ProductBlock() {
    const router = useRouter();
    const [products, setProducts] = useState<IProduct[] | null>(null);
    const [isLoading, setIsLoading] = useState<boolean | null>(null);
    const [isError, setIsError] = useState<boolean>(false);
    const [visibleItems, setVisibleItems] = useState<number>(12);

    const fetchProducts = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await fetch(AppConfig.endpoints.products.GET_RECENT);
            if (response.ok) {
                const apiResponse = await response.json();
                if (apiResponse.success) {
                    setProducts(apiResponse.data as IProduct[]);
                } else {
                    setIsError(true);
                }
            }
        } catch (error: any) {
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setVisibleItems((prev) => Math.min(prev + 12, products?.length || 0));
                    }
                });
            },
            { rootMargin: '100px', threshold: 1.0 }
        );

        const target = document.querySelector('#loadMoreTrigger');
        if (target) {
            observer.observe(target);
        }

        return () => {
            if (target) {
                observer.unobserve(target);
            }
        };
    }, [products]);

    useEffect(() => {
        fetchProducts();
    }, [fetchProducts]);

    return (
        <>
            {/* <section className="max-w-screen-xl py-4 w-full">
                <div className="grid auto-rows-min gap-4 lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 justify-center">
                    {Array.from({ length: 12 }).map((_, i) => (
                        <div key={i} className="aspect-square rounded-xl bg-muted/50" />
                    ))}
                </div>
            </section> */}
            <section className="max-w-screen-xl py-4 w-full">
                <ProductGrid
                    products={products}
                    isLoading={false}
                    isError={false}
                    maxLength={12}
                />
            </section>
        </>

        // <div className="px-4">c
        //     {products ? (
        //         <div className="grid grid-cols-2 gap-3 sm:grid-cols-3 sm:gap-4 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">
        //             {products.slice(0, visibleItems).map((product) => (
        //                 <Card key={product.id}>
        //                     <div className="flex items-center justify-center p-0">
        //                         <Link href={`/item/${product.id}`}>
        //                             <Image
        //                                 src={product.image_url}
        //                                 alt={`${product.name} Image`}
        //                                 width={240}
        //                                 height={240}
        //                                 className="aspect-square rounded-t-lg"
        //                                 loading="lazy"
        //                             />
        //                         </Link>
        //                     </div>
        //                     <CardHeader className="p-0">
        //                         <CardTitle>
        //                             {product.prices.discount_perc ? (
        //                                 <div>
        //                                     <del>{product.prices.initial}</del>
        //                                     <span>{product.prices.final}</span>
        //                                 </div>
        //                             ) : (
        //                                 <div>{product.prices.initial}</div>
        //                             )}
        //                         </CardTitle>
        //                         <CardDescription onClick={() => router.push(`/item/${product.id}`)}>
        //                             {product.name.length > 60 ? product.name.slice(0, 57) + '...' : product.name}
        //                         </CardDescription>
        //                     </CardHeader>
        //                 </Card>
        //             ))}
        //         </div>
        //     ) : isLoading ? (
        //         <div className="grid grid-cols-2 gap-3 sm:grid-cols-3 sm:gap-4 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">
        //             {Array.from({ length: 12 }).map((_, index) => (
        //                 <Skeleton
        //                     key={index}
        //                     className="h-[300px] w-[200px] rounded-lg shadow-sm"
        //                 />
        //             ))}
        //         </div>
        //     ) : isError ? (
        //         <Alert variant="destructive">
        //             <AlertTitle>Error Occurred</AlertTitle>
        //             <AlertDescription>Please try again later.</AlertDescription>
        //         </Alert>
        //     ) : (
        //         <Skeleton className="h-[300px] w-[200px] rounded-lg shadow-sm" />
        //     )}

        //     {/* Триггер для подгрузки */}
        //     {products && visibleItems < products.length && (
        //         <div id="loadMoreTrigger" className="w-full h-10"></div>
        //     )}
        // </div>
    );
}
