'use client';

import { IProduct } from "@/types/api";
import Image from "next/image";
import ProductPrices from "./ProductPrices";
import { Skeleton } from "@/components/ui/skeleton";
import AddToFavouritesButton from "../buttons/AddToFavouritesButton";
import Link from "next/link";

type ProductCardProps = {
    product: IProduct;
    isLoading: boolean;
};

const ProductCard: React.FC<ProductCardProps> = ({ product, isLoading }) => {
    return (
        <div className="flex flex-col rounded-lg shadow-md border hover:shadow-sm cursor-pointer">
            <Image
                src={product.image_url}
                alt={`${product.name} Photo`}
                height={350}
                width={200}
            />
            <div className="p-1">
                {isLoading ? (
                    <Skeleton />
                ) : (
                    <>
                        <Link href={`/item/${product.id}/?product=${product.slug}`} className="cursor:pointer">
                            <span className="text-sm truncate max-w-full block" title={product.name}>
                                {product.name}
                            </span>
                            <ProductPrices
                                initial={product.initial_price}
                                current={product.current_price}
                                discount={product.discount}
                                isLoading={false}
                            />
                        </Link>
                    </>
                )}
            </div>
        </div>
    );
};

export default ProductCard;