'use client';

import { useCallback, useEffect, useState } from 'react';
import { AlertDescription, Alert, AlertTitle } from '../ui/alert';
import { ICategory } from '@/types/api';
import { AppConfig } from '@/utils/AppConfig';
import LoaderCustom from '../custom/LoaderCustom';
import Image from 'next/image';
import { Skeleton } from '../ui/skeleton';
import Link from 'next/link';

type CategoriesContainerProps = {
    categories: ICategory[];
};

export function CategoriesContainer({ categories }: CategoriesContainerProps) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    // const fetchCategories = useCallback(async () => {
    //     setIsLoading(true);
    //     try {
    //         const response = await fetch(AppConfig.endpoints.categories.GET);
    //         if (response.ok) {
    //             const apiResponse = await response.json();
    //             if (apiResponse.success) {
    //                 setCategories(apiResponse.data as ICategory[]);
    //             } else {
    //                 setIsError(true);
    //             }
    //         }
    //     } catch (error: any) {
    //         setIsError(true);
    //     } finally {
    //         setIsLoading(false);
    //     }
    // }, []);

    // useEffect(() => {
    //     fetchCategories();
    // }, [fetchCategories]);

    return (
        <>
            {isLoading ? (
                <LoaderCustom />
            ) : isError ? (
                <Alert variant="destructive">
                    <AlertTitle>Error Occurred.</AlertTitle>
                    <AlertDescription>Something went wrong...</AlertDescription>
                </Alert>
            ) : categories && categories.length > 0 ? (
                <div>
                    <div className="grid grid-cols-4 sm:grid-cols-5 md:grid-cols-6 lg:grid-cols-8 xl:grid-cols-10 gap-4 mx-auto max-w-full">
                        {categories.map((category) => (
                            <div
                                key={category.position}
                                className="flex flex-col items-center justify-center space-y-2"
                            >
                                <Link href={`/category/${category.slug}`}>
                                    <div className="w-[90px] sm:w-[100px] md:w-[120px] lg:w-[140px] h-[90px] sm:h-[100px] md:h-[120px] lg:h-[140px] aspect-square overflow-hidden rounded-full flex items-center justify-center bg-gray-100">
                                        <Image
                                            src={category.thumb_url}
                                            alt={category.category_name}
                                            width={120}
                                            height={120}
                                            className="w-full h-full object-cover"
                                        />
                                    </div>
                                    <p className="text-center text-sm mt-2 leading-none tracking-tight">
                                        {category.category_name}
                                    </p>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            ) : !isLoading ? (
                <div className="p-4">
                    <Alert variant="destructive">
                        <AlertTitle>Error Occurred.</AlertTitle>
                        <AlertDescription>Something went wrong...</AlertDescription>
                    </Alert>
                </div>
            ) : (
                <Skeleton className="h-[300px] w-[200px] rounded-lg shadow-sm" />
            )}
        </>
    );
};

export default CategoriesContainer;
