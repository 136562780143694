'use client';

import { Alert } from "@/components/ui/alert";
import { IProduct } from "@/types/api";
import React from "react";
import ProductCard from "./ProductCard";

type ProductGridProps = {
    products: IProduct[] | null;
    isLoading: boolean;
    isError: boolean;
    maxLength?: number;
};

const ProductGrid: React.FC<ProductGridProps> = ({ products, isLoading, isError, maxLength = 12 }) => {
    return (
        <>
            <div className="grid auto-rows-min gap-4 lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 justify-center">
                {isLoading && !products ? (
                    <div></div>
                ) : !isLoading && products && products.length > 0 ? (
                    products?.map(product => (
                        <React.Fragment key={product.id}>
                            <ProductCard product={product} isLoading={isLoading} />
                        </React.Fragment>
                    ))
                ) : !isLoading && products && products.length === 0 ? (
                    <div>No Products Found</div>
                ) : !isLoading && isError ? (
                    <div>
                        <Alert />
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default ProductGrid;
